import request from '@/utils/request'


export function add(data) {
    return request({
      url: 'api/shoBackForm',
      method: 'post',
      data
    })
  }
  export function audit(data) {
    return request({
      url: 'api/shoBackForm/check',
      method: 'post',
      data
    })
  }
  export function submit(data) {
    return request({
      url: 'api/shoBackForm/submit',
      method: 'post',
      data
    })
  }
export function get(id) {
  return request({
    url: "api/shoBackForm/" + id,
    method: "get"
  });
}

export function edit(data) {  
  return request({
    url: 'api/shoBackForm',
    method: 'put',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/shoBackForm/' + id,
    method: 'delete'
  })
}
export function stockIn(data) {  
  return request({
    url: 'api/shoBackForm/stockIn',
    method: 'post',
    data
  })
}
export function refund(data) {  
  return request({
    url: 'api/shoBackForm/refund ',
    method: 'post',
    data
  })
}

import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/shop',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/shop/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'api/shop',
    method: 'put',
    data
  })
}

export function getById(id) {
  return request({
    url: 'api/shop/getById/' + id,
    method: 'get',
  })
}

export function getByErpCode (erpId) {
  return request({
    url: "api/shop/getByErpCode",
    method: "get",
    params: {
      code: erpId
    }
  })
}
<template>
  <div class="print-retail-return-order" v-if="form">
    <div class="title ta-c flex">退货单</div>

    <div class="h">
      <div style="padding-right: 1em;">
        <div class="h">
          <div class="no-flex info_title">合同号：</div>
          <div class="flex">{{form.contractNo}}</div>
        </div>
        <div class="h">
          <div class="no-flex info_title">客户名称：</div>
          <div class="flex">{{form.buyerName}}</div>
        </div>
        <div class="h">
          <div class="no-flex info_title" style="width: 7em;">申请退货日期：</div>
          <div class="flex">{{new Date(form.createAt).format("yyyy/M/d")}}</div>
        </div>
      </div>
      <div style="padding-right: 1em;">
        <div class="h">
          <div class="no-flex info_title">销售单号：</div>
          <div class="flex">{{form.orderFormCode}}</div>
        </div>
        <div class="h">
          <div class="no-flex info_title">客户电话：</div>
          <div class="flex">{{form.buyerPhone}}</div>
        </div>
        <div class="h">
          <div class="no-flex info_title">销售门店：</div>
          <div class="flex">{{shop ? shop.shopName : ""}}</div>
        </div>
      </div>
      <div style="padding-right: 1em;">
        <div class="h">
          <div class="no-flex info_title">退货单号：</div>
          <div class="flex">{{form.formCode}}</div>
        </div>
        <div class="h">
          <div class="no-flex info_title">退货地址：</div>
          <div class="flex">{{form.sendProvince}}{{form.sendCity}}{{form.sendDistrict}}{{form.sendAddress}}</div>
        </div>
        <div class="h">
          <div class="no-flex info_title">销售人员：</div>
          <div class="flex">{{form.sellerName}}</div>
        </div>
      </div>
      <div>
        <div>&nbsp;</div>
        <div class="h">
          <div class="no-flex info_title">打印日期：</div>
          <div class="flex">{{new Date().format("yyyy/M/d")}}</div>
        </div>
        <div class="h">
          <div class="no-flex info_title">联系电话：</div>
          <div class="flex">{{shop ? shop.telephone : ""}}</div>
        </div>
      </div>
    </div>

    <table cellpadding="0" cellspacing="0" border="0">
      <thead>
        <th style="width: 25pt;"></th>
        <th style="width: 90pt;"></th>
        <th style="width: 120pt;"></th>
        <th style="width: 50pt;"></th>
        <th style="width: 45pt;"></th>
        <th style="width: 30pt;"></th>
        <th style="width: 45pt;"></th>
        <th style="width: 60pt;"></th>
      </thead>
      <tr>
        <td class="ta-c">序列</td>
        <td class="ta-c">ERP编码</td>
        <td class="ta-c">商品名称</td>
        <td class="ta-c">规格型号</td>
        <td class="ta-c">退货数量</td>
        <td class="ta-c">单位</td>
        <td class="ta-c">退货方式</td>
        <td class="ta-c">备注</td>
      </tr>
      <template v-for="(o, i) in form.items">
        <tr :key="o.id">
          <td class="ta-c">{{i + 1}}</td>
          <td>{{o.erpCode}}</td>
          <td>{{o.goodsName}}</td>
          <td>{{$goodsSpecConvert(o.goodsSpec)}}</td>
          <td class="ta-c">{{o.count}}</td>
          <td class="ta-c"></td>
          <td class="ta-c"></td>
          <td class="ta-c">{{o.info}}</td>
        </tr>
      </template>
      <template v-if="form.items.length < 13">
        <tr v-for="i in (13 - form.items.length)" :key="'e' + i">
          <td class="ta-c">{{form.items.length + i}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <tr>
        <td colspan="2">总计数量：</td>
        <td></td>
        <td></td>
        <td class="ta-c">{{form.backTotal}}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td colspan="8" style="height: 8em;" valign="top">
          退货原因：
          <br />
          {{form.info}}
        </td>
      </tr>
      <tr>
        <td colspan="8" style="height: 8em;" valign="top">
          备注：请
          <b style="text-decoration: underline;">上门收货人</b>确认货物是否完好无损，如有破损，需写明具体情况。
        </td>
      </tr>
    </table>
    <div class="h">
      <div style="width: 37%;">客户签字：</div>
      <div style="width: 37%;">
        上门收货人：
        <br />签收日期：
      </div>
      <div class="flex">
        退货入库确认人：
        <br />入库日期：
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "@/api/shopOrderBackForm";
import { getById } from "@/api/shopInformation";
export default {
  props: {
    id: String | Number,
  },
  data() {
    return {
      form: null,
      shop: null,
    };
  },
  computed: {
    sums() {
      let count = 0,
        amount = 0;
      if (this.form && this.form.items && this.form.items.length) {
        this.form.items.forEach((o) => {
          count += o.count;
          amount += o.realPrice * o.count;
        });
      }
      return {
        count,
        amount,
      };
    },
  },
  methods: {
    load() {
      get(this.id)
        .then((res) => {
          res.backTotal = 0;
          (res.items || []).forEach((o) => {
            res.backTotal += o.count || 0;
          });
          this.form = res;
          this.loadShopInfo(res.shopId);
        })
        .catch((e) => {
          this.$emit("error", e);
        });
    },
    loadShopInfo(id) {
      getById(id)
        .then((res) => {
          this.shop = res;
          this.$nextTick((_) => {
            this.$emit("loaded");
          });
        })
        .catch((e) => {
          this.$emit("error", e);
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="less">
.print-retail-return-order {
  .info_title {
    width: 5em;
    text-align: right;
    white-space: nowrap;
  }
  .title {
    font-size: 24pt !important;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, Arial, sans-serif !important;
    padding: 0 !important;
    line-height: 1;
    margin-bottom: 1rem;
  }
  table {
    table-layout: fixed;
    margin: 15pt 0 !important;
    font-size: 10.5pt;

    td {
      white-space: normal;
      word-break: break-all;
    }
  }

  .inner-cell {
    padding: 0 !important;
    > table {
      margin: 0 !important;
      border: none !important;
      tr:first-child {
        td {
          border-top: none !important;
        }
      }
      td:last-child {
        border-right: none !important;
      }
    }
  }
  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10pt;

    &:before {
      content: "";
      position: relative;
      width: 8pt;
      height: 8pt;
      margin-right: 2pt;
      border: #000 solid 1px;
    }
  }
}
</style>